

.sideWrap{
    min-width: 265px;
    max-width: 270px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 30px 0px;
}

.threeLine{
    display: none;
}


@media (max-width:1100px) {
    .sideWrap{
       display: none;
    }

    .threeLine{
        display: block;
    }
}

.act{
   background: #4D32920D;
}
 .dashbord{
 display: flex;
 padding: 10px 20px;
 gap: 10px;
 width: 100%;
 /* background: #4D32920D; */
 align-items: center;
 
}
 .dashbord .dsad{
display: flex;
align-items: center;
justify-content: space-between;
width: 100%;
cursor: pointer;
}

 .dashbord .dsad p{
    /* font-family: "Roboto", sans-serif;    */
    font-family: gilroy-medium !important;
    font-size: 18px;
    /* font-weight: 400; */
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
color: #464E59;    
}

.opeDashWrap{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 5px;

}
.opeDashWrap .SOpeDsh{
   display: flex;
   padding: 10px 20px 0px 20px;
   align-items: center;
   gap: 10px;
   cursor: pointer;
   
}
.opeDashWrap .radio{
    width: 8px;
    height: 8px;
    border: 0.75px;
    border: 0.75px solid #464E59;  
    border-radius: 50%;  

}
.opeDashWrap span{
    font-family: "Roboto", sans-serif;   
    font-size: 18px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
color: #464E59;    
}

 .nanItem{
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 10px 20px;
  /* width: 100%; */
}
.lo h2{
    font-family: gilroy-medium !important;
    font-size: 18px;
    font-weight: 400;
    letter-spacing: 0px;
    text-align: left;
    color: #060606;
}
.lo{
    position: absolute;
    top: 40px;
    z-index: 200;
    padding: 0px 30px;
}
 .nanItem span{
/* font-family: "Roboto", sans-serif;   */
font-family: gilroy-medium !important;
 font-size: 18px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
color: #464E59;    
}
