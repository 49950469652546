.navBr_wrap {
    width: 100%;
    position: relative;
    background-color: white;
    z-index: 200;
    overflow-y: hidden;
}

.navBr_Cont {
    /* max-width: 1440px; */
    margin: 0 auto;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 30px 40px;
    justify-content: space-between;

}



.navBr_Cont h2 {
    /* font-family: "Roboto", sans-serif; */
    font-family: gilroy-medium !important;
    font-size: 18px;
    font-weight: 400;
    letter-spacing: 0px;
    text-align: left;
    color: #060606;

}


.navBr_Cont .createOBtn {
    width: 144px;
    height: 48px;
    padding: 10px;
    border-radius: 5px;
    background-color: #4D3292;

}

.navBr_Cont .createOBtn span {
    /* font-family: "Roboto", sans-serif; */
    font-size: 18px;
    font-weight: 400;
    letter-spacing: 0em;
    text-align: left;
    color: #FFFFFF;
    font-family: gilroy-semibold !important;
}

.smallScreenSide {
    display: none;
    position: relative;
}



.threeLine {
    font-size: 40px;

}

.allSidebar {
    position: fixed;
    background-color: white;

}


@media (max-width:1100px) {
    .navBr_Cont h2 {
        display: none;

    }
    .navBr_wrap{
        position: fixed;
        top: 0;
    }
    .smallScreenSide {
        display: block;
        position: relative;
    }


}

@media (max-width:400px) {

    .navBr_Cont {
        padding: 30px 20px;
    }
}