.sysSetWrap {
    width: 100%;
    position: relative;
}

.loader {
    width: 48px;
    height: 48px;
    margin: 20px auto;
    border: 5px solid #FFF;
    border-bottom-color: #4D3292;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.notDtafound {
    font-family: "Roboto", sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: #060606;
    padding: 20px 0px;
    margin: 0 auto;
}

.sySeCont {
    display: flex;
    width: 100%;
}

.sySrIGHT {
    max-width: calc(100% - 220px);
    width: 100%;
    padding: 45px;
    min-height: calc(100vh - 88px);
    background-color: #f5f5f5;

}


.sSetRCon {
    max-width: 1200px;
    width: 100%;
    display: flex;
    margin: 0 auto;
    flex-direction: column;
    gap: 24px;
}

nav {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

nav img {
    cursor: pointer;
}

.syMain {
    width: 100%;
    display: flex;
    gap: 40px;

}

.stMaLe {
    max-width: 300px;
    width: 100%;
    display: flex;
    flex-direction: column;


}

.siStMale {
    width: 100%;
    height: 75px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 24px;
    cursor: pointer;


}

.siStMale p {
    /* font-family: "Roboto", sans-serif; */
    font-family: gilroy-medium !important;
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
}

.otherStm p {
    font-family: gilroy-medium !important;
    color: #060606;
    font-weight: 400;
}

.otherStm {

    background-color: white;
}

.currentSym {
    background-color: #4D3292;
}

.currentSym p {
    color: white;
}

.stMaRig {
    max-width: 1200px;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.stMRiFir {
    width: 100%;
    display: flex;
    background-color: white;
    align-items: center;
    justify-content: space-between;
    padding: 20px 40px;
}

.stmRFLef {
    display: flex;
    gap: 10px;
    align-items: center;
    border: 1px solid #CED4DA;
    max-width: 248px;
    width: 100%;
    border-radius: 5px;
    padding: 10px 20px;
    opacity: 0.1px;

}

.stmRFLef select {
    width: 60px;
    height: 34px;
    border-radius: 5px;
    border: 0.75px solid #CED4DA;
    -webkit-appearance: none;
    font-weight: 400;
    font-family: gilroy-medium !important;
    opacity: 70%
}

.stmRFLef span {
    /* font-family: "Roboto", sans-serif; */
    font-family: gilroy-medium !important;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    color: #293240;
    opacity: 80%;
}

.stmRFRig {
    width: 155px;
    height: 54px;
    border-radius: 27px;
    opacity: 0.1px;
    border: 1px solid #CED4DA;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;

}


.stmRFRig input {
    font-family: "Roboto", sans-serif !important;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: left;
    color: #293240;
    width: 60%;
    border: none;
    outline: none;

}

.stMrSec {
    width: 100%;
    padding: 13px 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #f9f7ff;
}

.VirstMrSec {
    display: none;
    width: 100%;
    padding: 13px 20px;
    justify-content: space-between;
    align-items: center;
}

.VirstMrSec p {
    /* font-family: "Roboto", sans-serif; */
    font-size: 18px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: left;
    color: #060606;
    font-family: gilroy-semibold !important;
}

.stMrSec p {
    /* font-family: "Roboto", sans-serif; */
    font-family: gilroy-semibold !important;
    font-size: 18px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: left;
    color: #060606;
    text-transform: uppercase;
}

.stmRtHIR {
    display: flex;
    flex-direction: column;
    background-color: white;
}

.VirstmRtHIR {
    display: none;
    flex-direction: column;
    background-color: white;
}

.singTtpe {
    border: 1px solid #f5f5f5;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px 40px;

}

.singTtpe h2 {
    /* font-family: "Roboto", sans-serif; */
    font-family: gilroy-medium !important;
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: left;
    color: #293240;
}
.stmRFRig input::placeholder{
    color: #293240;
    font-weight: 400;
    font-size: 16px;
    /* font-family: gilroy-medium !important; */
}
.singTtpe .STpeRi {
    display: flex;
    align-items: center;
    gap: 14px;
}

.STpeRi img {
    cursor: pointer;
}

.creNoWrap {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 200;

}

.cretNPoup {
    max-width: 500px;
    width: 100%;
    /* max-height: 270px; */
    /* height: 100%; */
    height: fit-content;
    background-color: white;
    display: flex;
    flex-direction: column;
    gap: 18px;
    z-index: 410;
    padding: 18px 20px;
    margin: 0px 10px;

}

.cretNPoup h2 {
    /* font-family: "Roboto", sans-serif; */
    font-family: gilroy-medium !important;
    font-size: 18px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    color: #060606;

}

.cretNPoup label {
    display: flex;
    flex-direction: column;
    gap: 12px;

}

.cretNPoup label p {
    /* font-family: "Roboto", sans-serif; */
    font-family: gilroy-medium !important;
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    color: #060606;
}

.cretNPoup label input {
    max-width: 460px;
    height: 54px;
    border-radius: 5px;
    /* border: 1px solid #000000; */
    border: 1px solid #7d7c7c !important;
    padding: 18px 16px;
    outline: none;
    color: #293240;
    font-weight: 400;
    font-size: 14px;
    opacity: 60%;
    font-family: gilroy-medium !important;
}
.cretNPoup label input::placeholder{
    color: #293240;
    font-weight: 400;
    font-size: 14px;
    font-family: gilroy-medium !important;
}
.crCBtn {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    gap: 16px;
}

.canceBtn {
    max-width: 65px;
    width: 100%;
    height: 37px;
    border-radius: 5px;
    opacity: 0.5px;
    /* border: 1px solid #000000; */
    border: 1px solid #7d7c7c !important;
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: center;
    color: #060606;

}

.createBtn {
    max-width: 65px;
    width: 100%;
height: 37px;
    /* height: 100%; */
    border-radius: 5px;
    font-family: gilroy-medium !important;
    /* font-family: "Roboto", sans-serif; */
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: center;
    background-color: #4D3292;
    color: #FFFFFF;
}


.VirstMRiFir {
    display: none;
}

.virNav {
    display: none;
}

@media (max-width:1440px) {
    .stMaLe {
        max-width: 280px;
        width: 100%;
    }

    .syMain {

        gap: 20px;

    }

    .sySrIGHT {

        padding: 35px;


    }
}

@media (max-width:1100px) {
    .sySrIGHT {
        max-width: 100%;
        width: 100%;
        margin-top: 100px;

    }

    .sySrIGHT {
        padding: 40px 20px;
    }


}

@media (max-width:900px) {
    .stMRiFir {
        padding: 20px 10px;
    }

    .stmRFLef {

        max-width: 160px;
        width: 100%;
        padding: 10px;


    }

    .stmRFLef select {
        width: 30px;
        height: 34px;
        border-radius: 5px;
        border: 0.75px solid #CED4DA;

    }

    .stmRFLef span {
        font-size: 12px;
    }

    .stmRFRig {
        width: 130px;
        height: 44px;

    }

    .stmRFRig input {
        font-size: 12px;
    }

}

@media (max-width:800px) {
    .stMaLe {
        max-width: 240px;
        width: 100%;
    }

}

@media (max-width:700px) {

    nav {
        display: none;
    }

    .stMRiFir {
        display: none;
    }

    .syMain {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 20px;

    }

    .stMaLe {
        max-width: 100%;
        width: 100%;

    }

    .VirstMRiFir {
        background-color: transparent;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 10px;
        flex-wrap: wrap;
    }

    .VirstMRiFir .stmRFLef {
        background-color: white;

    }

    .VirstMRiFir .stmRFRig {
        background-color: white;

    }

    .stMrSec {
        display: none;
    }

    .stmRtHIR {
        display: none;
    }

    .VirstMrSec {
        display: flex;
    }

    .VirstmRtHIR {
        display: flex;
    }

    .VirstmRtHIR .singTtpe {

        padding: 24px 20px;

    }

    .virNav {
        width: 100%;
        display: flex;
        justify-content: center;
        background-color: white;
        padding: 20px 0px;
    }
}