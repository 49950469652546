@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

*{
 margin: 0px;
 padding: 0px;
 box-sizing: border-box;
 overflow-x: hidden;
 font-family: gilroy !important;
}

@font-face {
    font-family: gilroy;
    src: url('./Common/gilroy/Gilroy-Regular.ttf');
}
@font-face {
    font-family: gilroy-medium;
    src: url('./Common/gilroy/Gilroy-Medium.ttf');
}

@font-face {
    font-family: gilroy-semibold;
    src: url('./Common//gilroy/Gilroy-SemiBold.ttf');
}

@font-face {
    font-family: gilroy-bold;
    src: url('./Common/gilroy/Gilroy-Bold.ttf');
}