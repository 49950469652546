@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

*{
  padding: 0px;
  margin: 0px;
  box-sizing: border-box;
  font-family: "Roboto", sans-serif !important;
}

.ssItem{
  position: fixed;
  bottom: 40px;
}
.iron-login-main{
  /* max-width: 1500px; */
  width: 100%;
  display: block;
  margin: auto;
}
.hi h2{
  display: none;
}
.action_box{
  background-color: white;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  padding: 10px;
  /* position: fixed; */
}
.action_box p{
  margin: 10px 0px;
}
.iron-login-flex{
  display: flex;
  justify-content:space-between;

}
.iron-login-left{
  width: 50%;
  position: relative;
  z-index: 2;
}
.iron-login-left img::before{
  content: "";
  background-color: #000000;
  position: absolute;
  opacity:50%;
  width: 100%;
  height: 100%;
  z-index: -1;
}
.iron-login-left img{
  width: 100%;
  height: 100vh;
  object-fit: cover;
}

.right{
  width: 50%;
  position: relative;
  z-index:1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.right::before{
  content: "";
  background-color: #000000;
  opacity: 5%;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2;
}
.login-page{
  width: 481px;
  border-radius: 16px;
  padding: 30px;
  background-color: #fff;
  position: relative;
  z-index: 10;
}
.login-page h2{
  font-size: 32px;
  line-height: 39.62px;
  font-weight:400;
  color: #060606;
  text-align: center;
  font-family: gilroy-bold !important;
}
.login-form-main{
  padding-top: 20px;
}
.login-form{
  display: flex;
  flex-direction: column;
  text-align:start;
  gap: 8px;
  padding-top: 20px;
}
.login-form label{
  color: #060606;
  font-weight: 400;
  font-size: 18px;
  font-family: gilroy-medium !important;
}
.login-form label b{
  font-size: 18px;
  line-height: 21.83px;
  font-weight: 400;
}
.login-form input{
  max-width: 401px;
  width: 100%;
  height: 45px;
  padding: 10px;
  border: 0.75px solid rgb(212, 210, 210);
  border-radius: 5px;
  outline: none;
  color: #293240;
  opacity: 70%;
}
.login-form input::placeholder{
  color: #293240;
  font-weight: 400;
  font-size: 16px;
  opacity: 70%;
}
.forget-pass{
  display: flex;
  justify-content: space-between;
  align-items: center;
 padding-top: 30px;
}
.check-remember{
  display: flex;
  justify-content: center;
  align-items:center;
  gap: 5px;
  text-align: center;
}
/* clears the 'X' from Internet Explorer */
input[type=search]::-ms-clear {  display: none; width : 0; height: 0; }
input[type=search]::-ms-reveal {  display: none; width : 0; height: 0; }

/* clears the 'X' from Chrome */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration { display: none; }
.check-remember input{
  width: 25px;
  height: 25px;
  border: 1px solid gray;
}
.check-remember label{
  font-size: 16px;
  line-height: 19.41px;
  font-weight: 400;
  color: #293240;
  opacity: 0.7;
  font-family: gilroy-medium !important;
}
.login-password span a{
  font-size: 16px;
  line-height: 19.41px;
  opacity: 50%;
  font-weight: 400;
  text-decoration: none;
  font-family: gilroy-medium !important;
  color: #060606;
  /* color:gray; */
  text-decoration: underline;
}
.Login-btn{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding-top: 40px;
  gap: 25px;
}
.Login-btn button{
  width: 400px;
  height: 64px;
  border-radius: 6px;
  background-color: #4D3292;
  color: white;
  font-size: 18px;
  line-height: 22.05px;
  font-weight: 400;
  border-style: none;
  font-family: gilroy-semibold !important;
}
.Login-btn p{
  font-size: 16px;
  line-height: 19.41px;
  font-weight: 400;
  color: gray;
}
/* .lt{
  top: -26px;
} */
.cli1{
  display: block;
  margin:10px auto;
  max-width: 1440px;
  width: 100%;
}

.sen{
  background-color: red;
}
.hy{
  background-color: purple;
}
.btn222{
  margin: 0px 10px;
  color: #1D3966;
  font-weight: 400;
  font-size: 18px;
  font-family: gilroy-medium !important;
}
.ordery{
  max-width: 600px;
  width: 100%;
}

.order_de{
  max-width: 600px;
  width: 100%;
  border:1px solid #00000033;
  border-radius: 7px;
  background-color: white;
  /* padding: 0px 20px; */
}
.order_head{
  border-bottom: 1px solid #00000033;
  height: 54px;
  max-height: 100%;
  display: flex;
  align-items: center;
  padding: 0px 20px;
  background: #f5f5f5 !important;
  /* pos */
}
.order_head h2{
  color: #060606;
  font-weight: 400;
  font-size: 19.5px;
  font-family: gilroy-medium !important;
}
.order_head1{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 28px;
  height: 54px;
  max-height: 100%;
  border-bottom: 1px solid #00000033;
}
.order_body{
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0px 28px;
  height: 250px;
  margin-top: 12px;
}
.order_footer{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 28px;
  border-top: 1px solid #00000033;
  height: 54px;
}
.order_de p{
  color: #060606;
  font-family: gilroy-semibold !important;
  font-weight: 400;
  font-size: 14px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  gap: 10px;
}
.order_de span{
  font-family: gilroy-medium !important;
  font-weight: 400;
  font-size: 14px;
  color: #060606;
}
.hit{
  width: 180px !important;
}
.login-form11{
  margin: 0px 10px;
}
.sites{
  display: flex;
  align-items: center;
  gap: 5px;
  flex-wrap: wrap;
}
/* .hinh{
  margin: 15px 0px;
} */
input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
               -webkit-appearance: none;
                margin: 0;
        }
 
        input[type=number] {
            -moz-appearance: none;
        }
@media only screen and (max-width:1100px){
  .hi h2{
    display: flex !important;
  }
}
@media (max-width:750px) {
  .iron-login-flex{
  display: flex;
  flex-direction: column;
  align-items: center;
  }
  .order_head1{
     flex-wrap: wrap;
     height: 60px;
  }
  .order_body{
    flex-wrap: wrap;
  }
  .order_footer{
    flex-wrap: wrap;
    height: 60px;
  }
  .iron-login-left{
    width: 100%;
   
  }
  .iron-login-left img{
   
    width: 100%;
   
  }
  
.right{
  width: 100%;
  transform: translateY(-150px);
 z-index: 20;
}

.right::before{
  background-color:white;
 
}
}
@media only screen and (max-width:350px){
  .yr{
    display: none;
  }
}

/* ---------------------Dashboard-------------------- */
.dashboard-under-man{
  position: relative;
  z-index:1;
}
.dashboard-under-man::before{
  content: "";
  background-color: #4D3292;
  opacity: 10%;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2;
}
.dashboard-under-main{
  padding: 30px 30px;
}
.dashboard-under-btnflex{
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.dashboard-under-btn{
  z-index: 1000;
  display: flex;
  justify-content: flex-end;
}
.dashboard-under-btn button{
  width: 194px;
  font-size: 18px;
  line-height: 24px;
  font-weight: 400;
  background-color: #fff;
  border-radius: 27px;
  padding: 12px 0px;
  display: flex;
  justify-content:center;
  align-items: center;
  text-align: center;
  gap: 15px;
}
.dashboard-under-flex{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.dashboard-card{
  width: 250px;
  background-color: #fff;
  z-index: 1000;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 6px;
  padding: 10px;
}
.dashboard-card p{
  font-size: 18px;
  line-height: 24.26px;
  font-weight: 500;
  color: #060606;
}
.dashboard-card span{
  font-size: 28px;
  line-height: 36.75px;
  font-weight: 500;
  color: #060606;
}
.entries-page-sec{
  padding: 0px 30px 0px 30px;
}
.entries-man-page{
  background-color: #fff;
  position: relative;
  z-index: 1000;
  border-radius: 5px;
}
.entries-flex-page{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 40px;
}
.entries-right-content{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
}
.enties-left-content button{
  width: 248px;
  padding: 10px 12px;
  border: 1px solid #CED4DA;
  color: #293240;
  border-radius: 7px;
  outline: none;
}
.enties-left-content button{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}
.enties-left-content button select{
  outline: none;
  border: 1px solid #CED4DA;
  width: 50px;
  height: 30px;
  border-radius:5px;
}
.entries-right-content button{
  width: 194px;
  font-size: 18px;
  line-height: 24px;
  font-weight: 400;
  background-color: #fff;
  border-radius: 27px;
  padding: 12px 0px;
  display: flex;
  justify-content:center;
  align-items: center;
  text-align: center;
  gap: 15px;
  border: 1px solid gray; 
}
.pk1 td{
  border-bottom: 1px solid rgb(223, 222, 222) !important;
  border-right: 1px solid rgb(223, 222, 222) !important;
}
.quat{
  width: 20%;
}
.quat1{
  width: 40%;
}
.sitsd{
  border-right: none !important;
}
.search-btn button{
  width: 155px;
  font-size: 18px;
  line-height: 24px;
  font-weight: 400;
  background-color: #fff;
  border-radius: 27px;
  padding: 12px 0px;
  display: flex;
  justify-content:center;
  align-items: center;
  text-align: center;
  gap: 15px;
  border: 1px solid gray; 
}

.dashboard-row-container{
  background-color: white;
  position: relative;
  z-index: 1000;
}
.dashboard-last-main{
  padding: 0px 30px 30px 30px;
}
.dashboard-list{
  display: flex;
  justify-content:space-between !important;
  align-items: center;
  text-align: center;
  /* gap: 60px; */
}

/* --------------------selectround page-------------------- */


.table-section{
  background-color:rgb(249 250 251);
  padding: 30px 40px;
}
.row-section-heading h1{
  font-size: 24px;
  /* line-height: 29.11px; */
  font-weight: 400;
  color: #060606;
  padding-bottom: 20px;
  font-family: gilroy-medium !important;
  text-align: center;
}
.select-1st-row h2{
  background-color: white;
  padding: 8px 0px 11px 20px;
  border-radius: 2px;
}
.select-1st-row-text h2{
  font-size: 20px;
  line-height: 29.11px;
  font-weight: 400;
  color: #060606;
  font-family: gilroy-medium !important;
}
.select-row-btn{
  display: flex;
  justify-content: flex-end;
  padding-top: 30px;
}
.select-row-btn-flex{
  display: flex;
  justify-content: center;
  align-items:end;
  gap:25px;
}
.select-row-btn-flex button{
  width: 144px;
  /* height: 48px; */
  font-size: 18px;
  line-height: 22.05px;
  font-weight: 400;
  color: #fff;
  padding: 12px 0px;
  border-radius: 5px;
  font-family: gilroy-semibold !important;
}
.select-row-btn-flex #edit{
  background-color: #E26E61;
} 
.select-row-btn-flex #print{
  background-color:#35B872;
} 
#select-dash{
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  gap: 70px;
}
.pk th{
  color: #060606;
  font-weight: 400;
  font-size: 15px;
  text-transform: uppercase;
  font-family: gilroy-semibold !important;
}
.pk1 td{
  color: #293240;
  font-weight: 400;
  text-align: center;
  font-size: 15px;
  font-family: gilroy-medium !important;
}
.sets{
  color: #232E38;
  font-weight: 400;
  font-size: 15.5px;
  font-family: gilroy-medium !important;
}
.sof{
  font-family: gilroy-semibold !important;
  color: #232E38;
  font-weight: 400;
  font-size: 16px;
  text-transform: capitalize;
}
.saty{
  background-color: #232E38;
}
.saty th{
  color: #FFFFFF;
  font-weight: 400;
  font-size: 15px;
  font-family: gilroy-medium !important;
}
.salar th,
.salar td{
  color: #000000;
  font-weight: 400;
  font-size: 15.5px;
  font-family: gilroy-medium !important;
}
.salar img{
  height: 23px;
  width: 23px;
}
/* .sall1{
  max-width: 550px;
  width: 100%;
} */
.sall1 th,td{
 white-space: nowrap;
}
/* .sall2{
  max-width: 550px;
  width: 100%;
} */
.sall2 th,td{
  white-space: nowrap;
}
.sing{
  max-width: 620px;
  width: 100%;
}
.sonm{
  float: right !important;
}
.pko{
  background-color: #F9F8F8;
}
.sara11{
  background-color: white;
  max-width: 620px;
  width: 100%;
  display: block;
  border: 1px solid gray;
  margin: auto;
  /* padding: 20px 0px; */
}
.sizied{
  font-size: 15.5px !important;
}
.sizied1{
  font-size: 16.4px !important;
}
.singEmp_profileL img{
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
.lpo{
  width: 34%;
}
/* .saty th{
  white-space: nowrap;
} */
.party_name{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 30px;
}
.party1 h2{
  color: #060606;
  font-weight: 400;
  font-size: 16px;
}
.ooo th{
  color: #060606;
  font-weight: 400;
  font-size: 15px;
  font-family: gilroy-medium !important;
}
.party1 span{
  color: #060606;
  font-weight: 400;
  font-size: 16px;
}
.pko th{
  color: #060606;
  font-weight: 400;
  text-align: center;
  font-size: 14px;
  /* font-family: gilroy-medium !important; */
  /* font-family: gilroy-semibold !important; */
  font-family: gilroy-bold !important;
  opacity: 80%;
}
.polor{
  height: 60px;
}
.oo1 th,
.oo1 td{
  color: #060606;
  font-weight: 400;
  font-size: 15px;
  font-family: gilroy-semibold !important;
}
.party1{
  border-bottom: 1px dashed #c7c5c5;
  padding: 2px 0px;
}
.soik{
  height: 90px;
}
.lokiu{
  border-bottom: 1px dashed #d3d1d1;
    width: 295%;
    position: relative;
    top: -20px;
}
.olol{
  height: 10vh;
}
.opming table tr{
  background-color: white !important;
}
.talentering label{
  color: #000000;
}
@media print {
  .selected_print{
    display: none !important;
  }
}
.soko{
  position: absolute;
  bottom: 0px;
}
@media only screen and (max-width:1100px){
  .shj{
    margin-top: 10rem;
  }
}
@media only screen and (max-width:500px){
  .party_name{
    flex-wrap: wrap;
  }
  .party1{
    margin: 8px 0px;
  }
  .ooo th{
    font-size: 12px;
  }
  .oo1 th, .oo1 td{
    font-size: 12px;
  }
  
}