.dashWrap {
    width: 100%;
    position: relative;

}

.dashWrap .dashCont {
    display: flex;
    width: 100%;
    /* gap: 20px; */
}

.dashWrap .dashRight {
    /* max-width: calc(100%-256px); */
    width: 100%;
    background-color: #f5f5f5;
    padding: 30px 50px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    min-height: calc(100vh - 88px);
}
.sara{
    position: relative;
}
.sara svg{
    position: absolute;
    top: 22px;
    right: 30px;
}
.action_box{
    position: absolute;
    box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.114);
    border-radius: 5px;
    background-color: white;
    z-index: 10;
}
.dashWrap .dRFirs {
    max-width: 1440px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.dashWrap .dRFirs select {
    width: 190px;
    height: 50px;
    display: flex;
    outline: none;
    border: none;
    border-radius: 27px;
    padding: 0px 30px;
    background-color: white;
    font-family: gilroy-medium !important;
    /* font-family: "Roboto", sans-serif; */
    font-size: 17px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: left;
    color: #293240;
}


.drSec {
    display: flex;
    align-items: center;
    max-width: 1440px;
    justify-content: space-between;
    gap: 20px;
    width: 100%;
    margin-top: 24px;
}

.drSec .siDrSec {
    /* width: 270px; */
    width: 100%;
    height: 168px;
    border-radius: 5px;
    background-color: #FFFFFF;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 16px;

}

.drSec .siDrSec h2 {
    /* font-family: "Roboto", sans-serif; */
    font-family: gilroy-medium !important;
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: #060606;
}

.drSec .siDrSec p {
    /* font-family: "Roboto", sans-serif; */
    font-family: gilroy-semibold !important;
    font-size: 27px;
    font-weight: 400;
    line-height: 37px;
    letter-spacing: 0em;
    text-align: left;
    color: #060606;

}

.tableStart {
    max-width: 1500px;
    width: 100%;
    background-color: #FFFFFF;
    border-radius: 5px;
    margin-top: 24px;
}

.tableStart .tabSec1 {
    width: 100%;
    display: flex;
    gap: 10px;
    justify-content: space-between;
    align-items: center;
    padding: 12px 20px;
}

.tableStart .tabS1Lef {
    max-width: 230px;
    width: 100%;
    border: 1px solid #CED4DA;
    height: 54px;
    border-radius: 5px;
    background-color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.tableStart .tabS1Lef select {
    /* font-family: "Roboto", sans-serif; */
    font-family: gilroy-medium !important;
    font-size: 14px;
    height: 34px;
    font-weight: 400;
    max-width: 60px;
    width: 100%;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
    border: 0.75px solid #CED4DA;
    color: #293240;
    outline: none;
    -webkit-appearance: none !important;
}

.tableStart .tabS1Lef span {
    /* font-family: "Roboto", sans-serif; */
    font-family: gilroy-medium !important;
    font-size: 18px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    color: #293240;
    opacity: 80%;
}

.tableStart .tabS1Rig {
    display: flex;
    align-items: center;
    gap: 20px;
}

.tableStart .tabS1Rig select {
    width: 190px;
    text-align: center;
    font-family: gilroy-medium !important;
    /* font-family: "Roboto", sans-serif; */
    font-size: 17px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0px;
    color: #293240;
    height: 50px;
    border-radius: 27px;
    opacity: 0.2px;
    border: 1px solid #CED4DA;
}
.gg{
    background-color: #fbf9fd;
}
.gg th{
    color: #060606;
    font-family: gilroy-semibold !important;
    font-weight: 400;
    text-transform: uppercase;
    font-size: 12.5px;
    white-space: nowrap;
    /* font-family: gilroy-medium !important; */
    /* font-weight: 500 !important; */
}
.select-row-btn{
    max-width: 600px;
    width: 100%;
    display: block;
    margin: auto;
}
.sss td{
    color: #293240 !important;
    font-family: gilroy-medium !important;
    font-weight: 400;
    font-size: 15.5px !important;
}
.tableStart .tabS1Rig .srch {
    display: flex;
    align-items: center;
    gap: 10px;
    width: 155px;
    height: 54px;
    border-radius: 27px;
    border: 1px;
    opacity: 0.1px;
    border: 1px solid #CED4DA;
    background-color: #FFFFFF;
    padding: 0px 20px;
}

.tableStart .tabS1Rig .srch input {
    width: 60%;
    border: none;
    outline: none;
    background-color: transparent;
    font-family: gilroy-medium !important;
    font-weight: 400;
}
.tableStart .tabS1Rig .srch input::placeholder{
    font-weight: 400;
    color: #293240;
    font-size: 16px;
    opacity: .8;
}
.cli{
    max-width: 1440px;
    width: 100%;
    display: block;
    margin: auto;
}
.sist{
    font-weight: 400;
    font-family: gilroy-medium !important;
}
/* .ansDataItem {
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;

} */

@media (max-width:1000px) {
    .dashWrap .dashCont {
        gap: 40px;
    }

    .dashWrap .dashRight {
        padding: 30px 20px;
        margin-top: 100px;
    }

}

@media (max-width:930px) {
    .drSec {
        flex-wrap: wrap;
        justify-content: center;
    }
}

@media (max-width:850px) {
    .tableStart .tabS1Lef {
        max-width: 150px;
        width: 100%;
    }

    .tableStart .tabS1Lef select {
        font-size: 12px;
        max-width: 30px;
    }

    .tableStart .tabS1Lef span {
        font-size: 12px;
    }

    .tableStart .tabS1Rig {
        gap: 10px;
    }

    .tableStart .tabS1Rig select {
        width: 124px;
        font-size: 12px;
        height: 44px;
    }

    .tableStart .tabS1Rig .srch {
        width: 125px;
        height: 44px;

    }

    .tableStart .tabS1Rig .srch input {
        font-size: 12px;

    }

}

@media (max-width:550px) {
    .tableStart .tabSec1 {
        flex-wrap: wrap;
    }

    .tableStart .tabS1Rig {
        flex-wrap: wrap;
    }

    .tableStart .tabS1Rig select {
        display: none;
    }

    .tableStart .tabSec1 {

        padding: 12px 10px;
    }
}